import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-541652ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuBlock = _resolveComponent("MenuBlock")!
  const _component_ChatListPage = _resolveComponent("ChatListPage")!
  const _component_SingleChatPage = _resolveComponent("SingleChatPage")!
  const _component_ios_wrapper = _resolveComponent("ios-wrapper")!
  const _directive_xtouch = _resolveDirective("xtouch")!

  return (_openBlock(), _createBlock(_component_ios_wrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MenuBlock, { class: "left-menu" }),
        _createVNode(_component_ChatListPage, {
          class: "ChatListPage",
          onChatSelected: $options.chatSelected
        }, null, 8, ["onChatSelected"]),
        _withDirectives(_createVNode(_component_SingleChatPage, {
          ref: "SingleChatPage",
          selected_chat: $data.selected_chat,
          class: "SingleChatPageOuter",
          onGoBack: $options.goBack
        }, null, 8, ["selected_chat", "onGoBack"]), [
          [
            _directive_xtouch,
            $data.swipeRightParams,
            "swipe",
            { right: true }
          ]
        ])
      ])
    ]),
    _: 1
  }))
}