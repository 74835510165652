
import MenuBlock from "@/components/MenuBlock.vue";
import {DataFilters} from "@xnpmpackages/xcomponents/dist/index";

/*import mainDb from "@/service/MainDb";*/

export default {
    name: "ChatListPage",
    components: {MenuBlock},
    mixins: [DataFilters],
    emits: ['chatSelected'],
    data() {
        return {
            chat_id: null,
        }
    },
    methods: {
        async itemClicked(event, chat) {
            this.chat_id = chat.id

            window.requestAnimationFrame(() => {
                event.target.closest('.chat-item').classList.add('active')
            })

            setTimeout(() => {

                window.requestAnimationFrame(() => {
                    event.target.closest('.chat-item').classList.remove('active')
                })

            }, 100)
            this.$emit('chatSelected', chat);
        }
    },

}
