

import {DataFilters, Helper} from '@xnpmpackages/xcomponents/dist/index'
import wss from "@/service/WebSocket";
//import xAudio from "@/service/Audio";
import IosWrapper from "@/components/IosWrapper.vue";
import AddMessageWithAttachment from "@/components/AddMessageWithAttachment.vue";
import ChatItem from "@/components/ChatItem.vue";
import ObserverInScroll from "@/components/ObserverInScroll.vue";
import * as idb from 'idb-keyval'
import IntersectDirective from "@/directives/ObserveDirectives";
import Skeleton from 'primevue/skeleton';

// Кастыль для айфона. Почему-то в wss колюэке все данные этого инстанса пустые
let x_selected_chat = null
let typing_timer

export default {
    name: "SingleChatPage",
    directives: {
        'intersect': IntersectDirective,
    },
    mixins: [Helper, DataFilters],
    props: {
        selected_chat: null,
    },
    data() {
        return {
            messages: [],
            additionalReadMessagesInfo: {},
            last_message_read_by_other: null,
            last_read_by_me_at_chat_open: null,

            showSkeleton: false,
        }
    },
    methods: {

        typing() {

            if (typing_timer)
                clearTimeout(typing_timer);

            typing_timer = setTimeout(() => {

                wss.send('cs_user_typing_new_message', {
                    chat_id: x_selected_chat?.id
                })

            }, 500)

        },

        getParamsForIntersectDirectiveForMessage(message) {
            return {
                message: message,
                observe_install_delay: 1000,
                callback: (el, bindings, params) => {

                    if (!x_selected_chat.last_reads?.[this.$store.state.userInfo.id]?.message_id || params.message.id > x_selected_chat.last_reads?.[this.$store.state.userInfo.id]?.message_id) {

                        wss.send('message_was_read', {
                            chat_id: x_selected_chat.id,
                            message_id: params.message.id
                        })

                    }

                }

            }
        },

        async topHasBeenReached() {

            const limit = 50

            try {
                const response = await wss.request('getMessagesOfChat', {
                    chat_id: x_selected_chat?.id,
                    older_than: this.messages?.[this.messages.length - 1]?.id,
                    limit: limit,
                })

                const scrollHeightWas = this.$refs.chatContainer.scrollHeight

                this.messages = [...this.messages, ...response?.data?.messages];

                await this.$nextTick()

                const scrollHeightDiff = this.$refs.chatContainer.scrollHeight - scrollHeightWas

                if (!this.$store.state.isIos) {

                    window.requestAnimationFrame(() => {
                        this.$refs.chatContainer.scrollTop += scrollHeightDiff
                    })

                }

            } catch (e) {
                this.$store.commit('addLog', e.message)
            }


        },

        newMessageInputEnterPressed(event) {

            if (document.documentElement.classList.contains('is-ios')) {

                this.sendMessage()

            } else {

                if (!event.shiftKey) {
                    event.preventDefault()
                    event.stopImmediatePropagation()
                    event.stopPropagation()
                    this.sendMessage()
                }

            }

        },

        async getChatMessages() {

            try {

                // Сначала прогружаем из БД
                this.messages = await idb.get('chat_message_' + x_selected_chat?.id)

                // Прогружаем с сервера
                await wss.waitConnect()
                const response = await wss.request('getMessagesOfChat', {chat_id: x_selected_chat?.id, limit: 20})
                this.messages = response?.data?.messages;

                // Сохраняем в БД
                idb.set('chat_message_' + x_selected_chat?.id, response?.data?.messages)

            } catch (e) {
                this.$store.commit('addLog', e.message)
            }

            await this.$nextTick()
            setTimeout(() => {

                const divider = this.$refs.chatContainer.querySelector('.new-messages-divider')

                if (divider) {
                    divider.scrollIntoView({block: 'center'})
                }

            }, 150)


        },

        sendMessage(event?: any) {

            if (event) {
                event.preventDefault()
                event.stopPropagation()
                event.stopImmediatePropagation()
            }

            this.scrollToBottom(true)

            wss.send('sendMessageToServer', {
                chat_id: x_selected_chat?.id,
                message: this.$refs.newMessageInput.innerHTML,
            })

            this.$refs.newMessageInput.innerHTML = '';

            //xAudio.playOutbound()

        },

        preventFocus(event) {
            event.preventDefault()
        },

        isChatScrolledOnBottom() {
            return ((this.$refs.chatContainer.scrollTop + this.$refs.chatContainer.clientHeight) + 20) >= this.$refs.chatContainer.scrollHeight;
        },

        scrollToBottom(force) {

            const was = this.$refs.chatContainer.style.scrollBehavior

            if (force) {
                this.$refs.chatContainer.style.scrollBehavior = 'auto'
            } else {
                this.$refs.chatContainer.style.scrollBehavior = 'smooth'
            }

            setTimeout(() => {
                if (was)
                    this.$refs.chatContainer.style.scrollBehavior = was
            }, 200)

            this.$refs.chatContainer.scrollTo({
                top: this.$refs.chatContainer.scrollHeight + 1000,
            })


        },

        fastScrollDownBtnClicked(event) {

            event.preventDefault()
            event.stopPropagation()

            this.scrollToBottom(false)

        },

        fileInputSelected() {
            this.$refs.AddMessageWithAttachment.show(x_selected_chat?.id)

        },

        swipeRight(data) {

            if (Math.abs(data.moveDifX) > 150) {
                data.element.removeProperty('position')
                data.element.removeProperty('let')
                this.$emit('goBack')
            } else {
                data.resetPosition(true)
            }

        },

        async reopenChat() {

            x_selected_chat = this.$store.state.chatList.find(item => item.id === x_selected_chat?.id)

            await this.getChatMessages();

            this.last_message_read_by_other = Object.values(x_selected_chat.last_reads).reduce((prev, currentItem: any) => {

                if (currentItem.message_id > prev && currentItem.user_id !== this.$store.state.userInfo.id) {
                    return currentItem.message_id
                } else {
                    return prev
                }

            }, 0)

            this.last_read_by_me_at_chat_open = x_selected_chat.last_reads[this.$store.state.userInfo.id].message_id

            setTimeout(() => {
                this.last_read_by_me_at_chat_open = x_selected_chat.last_reads[this.$store.state.userInfo.id].message_id
            }, 2000)

        },


    },
    mounted() {

        // добавление новых сообщений, когда приходят
        wss.addOnMessageReceiveCallBack('sendChatMessageToClient', (packet) => {

            if (packet?.data?.chat_id === x_selected_chat?.id) {
                this.messages.unshift(packet?.data)
            }

            const newChatList = this.$store.state.chatList?.map(item => {

                if (item.id === packet?.data?.chat_id) {
                    item.last_message = packet?.data
                }

                return Helper.methods.copyObjectByJSON(item)

            })


            this.$store.commit('setChatList', newChatList)

        })

        wss.addOnMessageReceiveCallBack('updateLastReadMessageOnClient', (packet) => {

            if (packet.data.chat_id !== x_selected_chat?.id)
                return;

            if (packet.data.user_id_which_read_message === this.$store.state.userInfo.id)
                return;

            if (packet.data.message_id > this.last_message_read_by_other)
                this.last_message_read_by_other = packet.data.message_id

        })

        window.addEventListener('focus', async () => {

            console.log('focus');

            if (x_selected_chat) {
                try {
                    //this.showSkeleton = true
                    await this.reopenChat(x_selected_chat)
                } finally {
                    //this.showSkeleton = false
                }
            }

        })

    },
    watch: {

        selected_chat: {

            immediate: false,

            async handler(newValue) {

                if (newValue) {

                    x_selected_chat = newValue
                    await this.reopenChat()

                } else {
                    x_selected_chat = null
                    this.messages = null
                }
            }

        },

    },
    components: {
        ObserverInScroll,
        ChatItem,
        AddMessageWithAttachment,
        IosWrapper,
        Skeleton,
    }


}
