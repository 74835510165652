import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75ab3c96"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XModal = _resolveComponent("XModal")!

  return (_openBlock(), _createBlock(_component_XModal, {
    ref: "XModal",
    "show-close-button": false,
    "blur-overlay": "",
    "full-transparent": "",
    "hide-footer": "",
    "show-overlay": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: {"width":"100vw","height":"100vh","display":"flex","justify-content":"center"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.hide && $options.hide(...args)))
      }, [
        _createElementVNode("img", {
          ref: "img",
          src: $data.image_source,
          alt: "Картинка",
          style: {"width":"100%","height":"auto","object-fit":"contain"}
        }, null, 8, _hoisted_1)
      ])
    ]),
    _: 1
  }, 512))
}