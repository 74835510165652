export default {

    mounted(el, binding) {

        const installObserve = () => {

            const observer = new IntersectionObserver(([entry]) => {

                if (entry.isIntersecting && binding.modifiers?.show) {

                    if (binding.value?.callback && typeof binding.value.callback === 'function') {
                        binding.value.callback(el, binding, binding.value);
                    }

                    if (binding.modifiers?.once)
                        observer.unobserve(el)

                }

                if (!entry.isIntersecting && binding.modifiers?.hide) {

                    if (binding.value?.callback && typeof binding.value.callback === 'function') {
                        binding.value.callback(el, binding, binding.value);
                    }

                    if (binding.modifiers?.once)
                        observer.unobserve(el)

                }

            })

            observer.observe(el)

        }

        if (binding.value?.observe_install_delay) {
            setTimeout(installObserve, binding.value?.observe_install_delay)
        } else {
            installObserve();
        }

    }

}

