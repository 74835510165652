
import {DataFilters, Helper} from "@xnpmpackages/xcomponents/dist/index";
import ModalImageViewer from "@/components/ModalImageViewer.vue";

export default {
    name: "ChatItem",
    components: {ModalImageViewer},
    mixins: [Helper, DataFilters],
    props: {
        message: null,
        isMessageRead: {
            type: Boolean,
            require: true
        }
    },
    methods: {

        async chatItemLongTap(data) {
            console.log(data.element);

            /*window.requestAnimationFrame(() => {
                console.log('1')
                data.element.classList.add('longTaped')
                console.log('2')
            })*/

        },

        async attachmentClicked(message, attachment) {

            this.$xapi.showBlockModal('Загрузка файла...')
            try {

                const url = '/chat/DownloadAttachment/' + message.id + '/' + attachment.guid

                if (attachment.thumbnail) {
                    const file = await this.$xapi.getFileAsFile(url)
                    const dataUrl = await Helper.methods.readFileAsDataUrl(file)
                    this.$refs.ModalImageViewer.show(dataUrl)

                } else {
                    await Helper.methods.downloadFileFromUrl(url)
                }

            } finally {
                this.$xapi.hideBlockModal()
            }


        },

        /*async swipeLeft(data) {

            data.resetPosition()

        }*/


    },
}
