
import {DataFilters, XModal} from "@xnpmpackages/xcomponents/dist/index";
import wss from "@/service/WebSocket";
import {v4 as generateGuid} from "uuid";

export default {
    name: "AddMessageWithAttachment",
    mixins: [DataFilters],
    components: {XModal},
    data() {
        return {
            files: [],
            text: null,
            chat_id: null,

            modalOpacity: '1',
        }
    },
    methods: {

        async send() {

            this.$xapi.showBlockModal('Отправка файлов...')

            try {
                await wss.send('sendMessageToServer', {
                    chat_id: this.chat_id,
                    message: this.$refs.newMessageInput.innerHTML,
                    attachments: this.files.map(item => {
                        return {
                            name: item.file.name,
                            size: item.file.size,
                            thumbnail: item?.thumbnail,
                            guid: generateGuid()
                        }
                    })
                })

                for (const item of this.files) {
                    await wss.sendBlob(item.file)
                }

                this.$refs.newMessageInput.innerHTML = '';
                this.clear()
                this.hide()

            } finally {
                this.$xapi.hideBlockModal()
            }

        },

        async clear() {
            this.files = []
            this.text = null
        },

        async show(chat_id) {

            this.modalOpacity = '0'
            await this.$refs.XModal.show()

            this.chat_id = chat_id

            this.$refs.FileUpload.click()

        },

        async hide() {
            this.modalOpacity = '1'
            this.$refs.XModal.hide()
        },

        fileSelected(event) {

            this.files = []

            if (event.target.files) {

                [...event.target.files].forEach(file => {

                    if (file.type.includes('image')) {

                        const fr = new FileReader()
                        fr.onload = () => {
                            const img = new Image();
                            img.onload = () => {

                                let canvas = document.createElement("canvas");
                                const aspect = img.width / img.height
                                canvas.width = 50;
                                canvas.height = canvas.width * aspect

                                const ctx = canvas.getContext("2d");
                                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                                const data = canvas.toDataURL("image/webp", 0.1);

                                this.files.push({
                                    file: file,
                                    thumbnail: data,
                                })

                            };

                            img.src = fr.result as any;
                        }
                        fr.readAsDataURL(file)


                    } else {

                        this.files.push({
                            file: file
                        })

                    }


                })

                this.modalOpacity = '1'

            }


        },

    }
}
