
export default {
    name: "ObserverInScroll",
    props: {
        margin: {
            type: String,
        },
        once: {
            type: Boolean,
            default: false,
        }
    },
    expose: ['stop', 'reinstall'],
    emits: ['show', 'hide'],
    data: () => ({
        observer: null
    }),
    methods: {
        stop() {

            if (this.observer)
                this.observer.disconnect();

            this.observer = null
            this.$store.commit('addLog', 'observer stop')
        },
        reinstall() {

            if (this.observer)
                this.stop()

            this.$store.commit('addLog', 'Observer installed')

            const margin = this.margin || '0px';
            this.observer = new IntersectionObserver(([entry]) => {

                if (entry) {

                    if (entry.isIntersecting) {

                        this.$emit('show');
                        if (this.once)
                            this.stop();

                    } else {

                        this.$emit('hide');
                        if (this.once)
                            this.stop();

                    }

                }

            }, {root: this.$el.parentElement, rootMargin: margin});

            this.observer.observe(this.$el);


        }
    },
    mounted() {

        this.reinstall()

    },
    unmounted() {
        this.stop()
    }
}
