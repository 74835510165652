

import {XModal} from '@xnpmpackages/xcomponents/dist/index'

export default {
    name: "ModalImageViewer",
    components: {XModal},
    data() {
        return {
            image_source: null,

        }
    },
    methods: {

        hide() {
            this.image_source = null
            this.$refs.XModal.hide()
        },

        show(src) {
            this.image_source = src
            this.$refs.XModal.show()
        },

    },
}
