
import IosWrapper from "@/components/IosWrapper.vue";
import ChatListPage from "@/pages/ChatListPage.vue";
import SingleChatPage from "@/pages/SingleChatPage.vue";
import MenuBlock from "@/components/MenuBlock.vue";
import {swipeParams} from '@xnpmpackages/xcomponents/dist/utils/xTouchDirective'

export default {
    name: "ChatPageWrapper",
    components: {MenuBlock, SingleChatPage, ChatListPage, IosWrapper},
    data() {
        return {
            selected_chat: null,
            swipeRightParams: {

                swipeThreshold: 100,
                autoReverseItemBack: true,
                forceCallbackBeforeTouchEnd: true,
                thresholdReachedCallback: (el: HTMLElement) => {

                    console.log(el);
                    this.goBack()

                }


            } as swipeParams
        }
    },
    methods: {

        async goBack() {

            this.$refs.SingleChatPage.$el.classList.remove('active')
            setTimeout(() => {
                this.selected_chat = null
            }, 100)

        },

        async chatSelected(selected_chat) {

            this.$refs.SingleChatPage.$el.classList.add('active')
            this.selected_chat = selected_chat

        },

    },

}
